import * as React from 'react'
import { Helmet } from 'react-helmet'

import Layout from '../components/layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGraduationCap, faRoute, faTools } from '@fortawesome/free-solid-svg-icons'

const LEquipe = () => {
    return (
        <>
            <Helmet>
                <title>Educa'dog : Centre d'éducation positive à Léglise - Notre équipe</title>
                <descrtiption>Educa'dog est un centre d'éducation canine positive situé chemin du Stria à Léglise, en province de Luxembourg. Nous n'utilisons que les méthodes positive et respectueuses du chien.</descrtiption>
            </Helmet>
            <Layout>
                <section id="l-equipe">
                    <h1 className="page-title fredoka">
                        L'équipe Educa'dog
                    </h1>
                    <div className="grid">
                        <section className="block">
                            <h2 className="block-title balsamiq">
                                Education canine positive
                            </h2>
                            <p>
                                Alexia est comportementaliste canin (Feel Doog) et assistante légale et administrative.<br />
                                Marie-Eve est assistante vétérinaire, physiothérapeute et future ostéopathe.
                            </p>
                            <p>
                                Nous serons vos monitrices principales, pour les 3 groupes.
                            </p>
                            <p>
                                Charline, Elise et Vicky, bénévoles, nous accompagnent et vous accueilleront sur le terrain. Elles seront formées par nos soins.
                            </p>
                            <FontAwesomeIcon icon={faGraduationCap} className="block-icon" />
                        </section>
                        <section className="block black">
                            <h2 className="block-title balsamiq">
                                Mantrailing
                            </h2>
                            <p>
                                Damien est le moniteur de mantrailing (recherche de personne).
                            </p>
                            <FontAwesomeIcon icon={faRoute} className="block-icon" />
                        </section>
                        <section className="block primary">
                            <h2 className="block-title balsamiq">
                                Support technique
                            </h2>
                            <p>
                                Nico, avec Damien, sont nos précieux supports techniques.
                            </p>
                            <FontAwesomeIcon icon={faTools} className="block-icon" />
                        </section>
                        <section className="block quote">
                            Une équipe de choc pour vous accueillir et vous aider !
                        </section>
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default LEquipe